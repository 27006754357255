import React, { useEffect, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import styled from "styled-components";

// components
import { Spinner } from "reactstrap";
import { LoadingButton } from "module/common/components/Buttons";
import Loadable from "module/common/components/Loadable";

import SubscriptionCard from "./SubscriptionCard";

const CreditCardInfoForm = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "btw" */ "module/common/components/Forms/CreditCardInfoForm"
    ),
});

// store
import { ICompany } from "module/main/store/Company/interfaces";
import { setPaymentInfo } from "module/main/store/Company/paymentInfo/action";
import { IPaymentInfo } from "module/main/store/Company/paymentInfo/interface";
import {
  last4 as companyLast4,
  paymentFetchStatus,
} from "module/main/store/Company/paymentInfo/selector";
import { getCompanyData } from "module/main/store/Company/selectors";
import { IPlan } from "module/main/store/SubscriptionStore/interfaces";
import { callPostEntitlements } from "module/main/store/SubscriptionStore/thunks";
import { useAppDispatch } from "module/main/store/hooks";

// utils
import { FeatureOverride } from "./SubscriptionCard";
import { CCRef } from "module/common/components/Forms/CreditCardInfoForm";
import { formatDate } from "module/common/helpers/utils";
import { ApiFetchStatus } from "module/common/helpers/variables";
import { usePlanChoices } from "./hooks";
import { renderFeeSections, subscriptionPlanFeeDescription } from "./utils";
import { setCompanyData } from "module/main/store/Company/actions";
import { selectEntitlementsStatus } from "module/main/store/SubscriptionStore/selectors";

interface Props {
  advanceToNextStep: () => void;
  goToPreviousStep: () => void;
  formData: any;
}

const BillingPage: React.FC<Props> = ({
  advanceToNextStep,
  goToPreviousStep,
  formData,
}) => {
  const dispatch = useAppDispatch();
  // selectors
  const last4 = useSelector(companyLast4);
  const company: ICompany = useSelector(getCompanyData);
  const paymentStatus = useSelector(paymentFetchStatus);
  const entitlementsStatus = useSelector(selectEntitlementsStatus);
  const [subscriptionOptions, isPlanLoading] = usePlanChoices(company.id);
  // useState
  const isPaymentInfoLoading = [ApiFetchStatus.Fetching, ApiFetchStatus.Initial].includes(
    paymentStatus
  );
  const isEntitlementsLoading = entitlementsStatus === ApiFetchStatus.Fetching;
  const [hideTransactionInfo, setHideTransactionInfo] = useState<boolean>(true);

  const selectedPlan: IPlan = formData.subscription;
  const skipTraceOnlyFeatures: FeatureOverride[] | null = formData.skipTraceOnlyFeatures;

  const isLoading = isPaymentInfoLoading || isEntitlementsLoading;

  const childRef = useRef<CCRef>();

  const currentEstimate = useMemo(() => {
    return subscriptionOptions.estimates.find(
      (estimate) => estimate.plan.id === selectedPlan.id
    );
  }, [subscriptionOptions]);

  useEffect(() => {
    // We don't want to show the confirm button if the user is on the free plan and doesn't have a card on file
    if (selectedPlan.id === "free" && !last4) return setHideTransactionInfo(false);
    setHideTransactionInfo(!!childRef.current?.isCreatingPaymentInfo);
  }, [childRef.current?.isCreatingPaymentInfo]);

  const onFormSubmit = (e: React.SyntheticEvent) => {
    if (!childRef.current) return;

    const { getBtReadyNonceRef, handleSubmit } = childRef.current;

    if (getBtReadyNonceRef()) {
      handleSubmit(e);
    }
  };

  const onSuccessfulSubmit = async ({ data }: Record<string, IPaymentInfo> = {}) => {
    data && dispatch(setPaymentInfo(data));
    if (hideTransactionInfo) {
      setHideTransactionInfo(false);
      if (selectedPlan.id !== "free") return;
    }
    dispatch(callPostEntitlements(company.id, selectedPlan.id)).then(({ data }) => {
      dispatch(setCompanyData(data));
      advanceToNextStep();
    });
  };

  const fieldSettings = {
    cardNumber: { columns: 12 },
    expiration: { columns: 4 },
    cvv: { columns: 4 },
    postalCode: { columns: 4, isVisible: true },
  };

  const disclaimer =
    selectedPlan.id !== "free"
      ? `By clicking "Confirm" you authorize Lead Sherpa to charge your
  credit card on file. You may cancel your subscription at any point to stop future monthly charges.`
      : null;

  const introMsg = last4
    ? `
      We already have your credit card information on file. Please confirm if you would like
      to proceed with the current card ending in ${last4}, or update your card information.
    `
    : `
      Please enter your credit card information to proceed with your integration.
    `;

  const hideTransactionSummary =
    selectedPlan.id === company.plan?.id && selectedPlan.id === "free";

  if (isPlanLoading) {
    return <Spinner color="primary" size="lg" />;
  }

  if (!currentEstimate) return null;

  const feeDescription = subscriptionPlanFeeDescription(
    company.plan,
    selectedPlan,
    currentEstimate,
    hideTransactionInfo
  );
  const feeSection = renderFeeSections(feeDescription);
  return (
    <div
      data-testid="ccinfo"
      className="d-flex mt-3 align-items-center justify-content-center pageContent w-100 px-5"
    >
      <div className="mr-5">
        {
          <SubscriptionCard
            className="m-0 ml-auto"
            plan={selectedPlan}
            featuresOverride={skipTraceOnlyFeatures}
          />
        }
      </div>
      <StyledDiv>
        <p className="textL mb-2 mt-2" data-testid="intro-msg">
          {introMsg}
        </p>
        <CreditCardInfoForm
          fieldSettings={fieldSettings}
          forwardedRef={childRef}
          onSuccessfulSubmit={onSuccessfulSubmit}
          hasCustomButton
        />
        {hideTransactionInfo ? (
          <LoadingButton
            className="mt-0 ml-auto"
            disabled={isLoading}
            type="submit"
            color="primary"
            onClick={onFormSubmit}
            loading={isLoading}
            data-testid="save-card-button"
            id="save-card-button"
            size="md"
          >
            Save Card
          </LoadingButton>
        ) : null}
        {hideTransactionSummary ? null : (
          <>
            {feeSection}
            {currentEstimate.datetimeChange &&
            currentEstimate.changeType === "downgrade" ? (
              <p className="textL my-2" data-testid="billing-date-msg">
                Your current {company.plan?.description} will continue to run until{" "}
                {formatDate(currentEstimate.datetimeChange)}.
              </p>
            ) : null}
          </>
        )}
        <div className="mt-2 mb-2 text-muted">{disclaimer}</div>
        <div className="mt-2 mb-2 text-muted">
          * Tax is calculated based on payment method region.
        </div>
        <ButtonWrapper className="d-flex justify-content-between pt-2">
          <Button
            id="back-button"
            disabled={isPaymentInfoLoading}
            color="secondary"
            onClick={goToPreviousStep}
            size="md"
          >
            Go Back
          </Button>
          <LoadingButton
            className="mt-0 ml-auto"
            disabled={isLoading || hideTransactionInfo}
            type="submit"
            color="primary"
            onClick={onFormSubmit}
            loading={isLoading}
            data-testid="changes-submit-button"
            id="changes-submit-button"
            size="md"
          >
            Confirm
          </LoadingButton>
        </ButtonWrapper>
      </StyledDiv>
    </div>
  );
};

const ButtonWrapper = styled.div`
  button {
    max-width: 300px;
  }
`;

const StyledDiv = styled.div`
  max-width: 860px;
`;

export default BillingPage;
